import React, { useContext } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { GolbalStateContext } from "../context"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

import PageIntro from "../components/pageIntro"
import Circles from "../components/Circles"
import MenuReset from "../components/MenuReset"

const ServicesPage = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setGlobalState] = useContext(GolbalStateContext)

  return (
    <Layout solidHeader>
      <SEO title="Services" />
      <MenuReset />

      <PageIntro>
        <h2>Services</h2>
      </PageIntro>

      <Content>
        <div className="row">
          <div
            className="content"
            onClick={e => {
              const element = e.target
              if (
                element.tagName === "A" &&
                element.getAttribute("href") === "#enquire"
              ) {
                e.preventDefault()
                setGlobalState(state => ({
                  ...state,
                  enquireOpen: true,
                }))
              }
            }}
          >
            <ReactMarkdown source={data.pageData.frontmatter.services_copy} />
            <Circles
              items={data.services.edges.map(({ node }) => ({
                title: node.frontmatter.title,
                to: node.fields.slug,
                src: node.frontmatter.thumb,
              }))}
            />
            <ReactMarkdown source={data.pageData.frontmatter.treatments_copy} />
            <Circles
              items={data.treatments.edges.map(({ node }) => ({
                title: node.frontmatter.title,
                to: node.fields.slug,
                src: node.frontmatter.thumb,
              }))}
            />
            <ReactMarkdown source={data.pageData.frontmatter.bottom_copy} />
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { page_id: { eq: "services" } }) {
      frontmatter {
        title
        services_copy
        treatments_copy
        bottom_copy
      }
    }

    services: allMarkdownRemark(
      filter: { fields: { slug: { glob: "/services/*" } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            thumb
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    treatments: allMarkdownRemark(
      filter: { fields: { slug: { glob: "/services/treatments/*" } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            thumb
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
