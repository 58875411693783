import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Circles = ({ items, borderTop = null, center }) => (
  <W center={center}>
    <Wrapper borderTop={borderTop} count={items.length}>
      {items.map(item => (
        <Link to={item.to} key={item.to} className="item">
          <div className="itemCircle">
            <img src={item.src} alt={item.title} />
          </div>
          <h5 className="itemTitle">{item.title}</h5>
        </Link>
      ))}
    </Wrapper>
  </W>
)

export default Circles
const W = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? `center` : `flex-start`)};
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin-top: 3em;
  padding-top: 3em;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${({ count }) => {
    if (count <= 6) {
      return `${count * 14}em`
    }
    return `84em`
  }};
  ${({ borderTop }) => borderTop && `border-top: 2px solid var(--blue);`}

  .item {
    margin: 0 1em 1.25em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    width: 12em;
  }

  .itemCircle {
    height: 10em;
    width: 10em;
    border-radius: 50%;
    background-color: var(--blue);
    background-size: cover;
    overflow: hidden;
    position: relative;
    border: 2px solid white;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a:hover .itemCircle {
    border: 2px solid var(--blue);
  }

  .itemTitle {
    color: var(--blue);
    text-decoration: none;
    font-size: 1.2em !important;
    margin-top: 0.2em;
  }
`
